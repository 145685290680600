<template>
  <div class="mt-8 ml-8">
    <span class="page_title"> Проверка размера изображений </span>
    <VCol class="d-flex flex-wrap align-center px-0" cols="6">
      <span class="input-title larger mr-6"> Поиск </span>
      <VCol class="px-0" cols="5">
        <RestaurantSelection
          :input-placeholder="'Введите название заведения...'"
          :outline-color="'#8c8c8c'"
          @restaurantSelected="restaurantSelected($event.pk)"
        />
      </VCol>
      <span v-if="showData" class="page_title ml-4">{{ emoji }}</span>
    </VCol>
    <VCol
      v-if="showData"
      class="d-flex flex-wrap"
      cols="12"
    >
      <ColumnGraph
        class="d-flex"
        cols="8"
        custom-key="pk"
        :values="foods"
        @value-selected="selectFood"
      />
      <VCol cols="4">
        <ImageUpload
          class="image"
          :class="showImage ? 'shown' : 'transparent'"
          :contains-title="false"
          description=""
          :image="selectedImage"
          image-down
          image-type="food"
          left-col="0"
          wrapper-cols="12"
          @update="updateFoodImage($event.logo)"
        />
      </VCol>
      <VCol class="d-flex align-end" cols="5">
        <ImageUpload
          allow-preview
          class="cover pb-0"
          :contains-title="false"
          description=""
          disable-padding
          :image="restaurant.image ? restaurant.image.url : ''"
          image-type="restaurant"
          left-col="0"
          wrapper-cols="11"
          @update="updateRestaurantImage($event.logo, 'image')"
        />
        <VTooltip right>
          <template v-slot:activator="{ on, attrs }">
            <Transition :key="restaurant.image.efficiency" name="graph">
              <div
                v-bind="attrs"
                class="custom-column mb-3"
                :style="{
                  height: `${100 - restaurant.image.efficiency}%`,
                  backgroundColor: `hsl(${restaurant.image.efficiency},80%,50%)`,
                }"
                v-on="on"
                @click="selectValue(value)"
              />
            </Transition>
          </template>
          <span class="text-primary">
            Размер: {{ (restaurant.image.size / 1024).toFixed(2) }}\MB
            <br>
            Совместимость: {{ restaurant.image.efficiency }}
          </span>
        </VTooltip>
      </VCol>
      <VCol class="d-flex align-end" cols="5">
        <ImageUpload
          allow-preview
          class="cover pb-0"
          :contains-title="false"
          description=""
          disable-padding
          :image="restaurant.logo ? restaurant.logo.url : ''"
          image-type="restaurant"
          left-col="0"
          wrapper-cols="11"
          @update="updateRestaurantImage($event.logo, 'logo')"
        />
        <VTooltip right>
          <template v-slot:activator="{ on, attrs }">
            <Transition :key="restaurant.logo.efficiency" name="graph">
              <div
                v-bind="attrs"
                class="custom-column mb-3"
                :style="{
                  height: `${100 - restaurant.logo.efficiency}%`,
                  backgroundColor: `hsl(${restaurant.logo.efficiency},80%,50%)`,
                }"
                v-on="on"
                @click="selectValue(value)"
              />
            </Transition>
          </template>
          <span class="text-primary">
            Размер: {{ (restaurant.logo.size / 1024).toFixed(2) }}\MB
            <br>
            Совместимость: {{ restaurant.logo.efficiency }}
          </span>
        </VTooltip>
      </VCol>
    </VCol>
  </div>
</template>

<script>
import { patchRestaurant, getRestaurantPerformance, patchFood } from '@/api/api';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import ColumnGraph from '@/components/ColumnGraph.vue';
import ImageUpload from '@/components/ImageUpload.vue';

export default {
  components: {
    RestaurantSelection,
    ColumnGraph,
    ImageUpload,
  },
  data() {
    return {
      showData: false,
      restaurant: {},
      foods: [],
      selectedImage: null,
      currentFood: {},
      showImage: false,
    };
  },
  computed: {
    emoji() {
      const emojiChangeStep = 25;
      const hundredPercent = 100;
      const emojis = ['🤬', '😡', '😠', '😊', '😇'];
      let totalEfficiency = 0;
      totalEfficiency += this.restaurant.logo ? this.restaurant.logo.efficiency : 0;
      totalEfficiency += this.restaurant.image ? this.restaurant.image.efficiency : 0;
      this.foods.forEach((food) => {
        totalEfficiency += food.logo.efficiency;
      });
      const restaurantItems = [
        this.restaurant.logo,
        this.restaurant.image,
      ];
      const itemsLength = restaurantItems.length + this.foods.length;
      const efficiencyRatio = totalEfficiency / itemsLength;
      const convertedRatioToPercents = hundredPercent - (hundredPercent - efficiencyRatio);
      const index = convertedRatioToPercents / emojiChangeStep;
      return emojis[Math.floor(index)];
    },
  },
  methods: {
    restaurantSelected(pk) {
      this.showData = false;
      getRestaurantPerformance(pk).then(
        (restaurant) => {
          this.restaurant = {
            image: restaurant.image,
            logo: restaurant.logo,
            pk: restaurant.pk,
            title: restaurant.title,
          };
          this.foods = restaurant.foods;
          this.showData = true;
        },
      );
    },
    selectFood(value) {
      this.currentFood = value;
      this.selectedImage = value.logo.url;
      this.showImage = true;
    },
    deselectFood() {
      this.currentFood = {};
      this.selectedImage = null;
      this.showImage = false;
    },
    updateFoodImage(logo) {
      if (this.selectedImage !== logo && logo) {
        patchFood(
          this.restaurant.pk,
          this.currentFood.food_type_pk,
          this.currentFood.pk,
          { logo },
        )
          .then(() => {
            this.deselectFood();
            this.restaurantSelected(this.restaurant.pk);
          });
      }
    },
    updateRestaurantImage(url, imageType) {
      const body = {};
      body[imageType] = url;
      patchRestaurant(this.restaurant.pk, body).then(() => {
        this.restaurantSelected(this.restaurant.pk);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.larger {
  font-size: 17px;
}

.image {
  transition: opacity .4s;
}

.transparent {
  opacity: 0;
}

.shown {
  opacity: 1;
}

.custom-column {
  background-color: red;
  width: 100%;
  opacity: .5;
  border: 1px solid $default_gray_color;
  border-radius: 5px 5px 0 0;
  transition: opacity .3s, height .4s, background-color .4s;

  &:hover{
    opacity: 1;
  }
}

.graph-complete-enter, .graph-complete-leave-to{
  opacity: 0;
  transform: translateY(30px);
}

.graph-complete-leave-active {
  position: absolute;
}
</style>
