<template>
  <VCol class="columns-wrapper d-flex justify-start" :cols="cols">
    <div
      v-for="value in values"
      :key="value[customKey]"
      class="each-column-wrap d-flex flex-wrap align-content-end align-end"
    >
      <VTooltip right>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            class="custom-column"
            :style="{
              height: `${100 - value[imageKey].efficiency}%`,
              backgroundColor: `hsl(${value[imageKey].efficiency},80%,50%)`,
            }"
            v-on="on"
            @click="selectValue(value)"
          />
        </template>
        <span v-if="showTooltip" class="text-primary">
          Размер: {{ (value[imageKey].size / 1024).toFixed(2) }}\MB
          <br>
          Совместимость: {{ value[imageKey].efficiency }}
          <br>
          Блюдо: {{ value.title }}
        </span>
      </VTooltip>
    </div>
  </VCol>
</template>

<script>
export default {
  props: {
    cols: {
      type: [String, Number],
      default: 12,
    },
    values: {
      type: Array,
      default: () => [],
    },
    customKey: {
      type: String,
      required: true,
    },
    imageKey: {
      type: String,
      default: 'logo',
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['value-selected'],
  methods: {
    selectValue(value) {
      this.$emit('value-selected', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixin.scss";

.columns-wrapper {
  height: 300px;
  overflow-x: scroll;
  .each-column-wrap {
    height: 95%;
    min-width: 1.5%;
    .custom-column {
      background-color: red;
      width: 100%;
      opacity: .5;
      border: 1px solid $default_gray_color;
      border-radius: 5px 5px 0 0;
      transition: opacity .3s, transform .4s, height .4s, background-color .4s;
      &:hover{
        opacity: 1;
        transform: scale(1.03);
      }
    }
  }
}
</style>
